<template>
  <v-container class="ma-2 pa-2">
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        md="12"
        sm="12"
        xm="12"
      >
        <v-card elevation="10">
          <v-toolbar
            dark
            dense
            color="brown"
          >
            Nueva poliza de soporte
            <v-spacer />

            <v-btn
              align="right"
              color="info"
              dark
              small
              class="ma-1"
              @click="regresar"
            >
              <v-icon>mdi-reply</v-icon>
            </v-btn>


            <v-btn
              align="right"
              color="success"
              dark
              small
              class="ma-1"
              @click="agregar"
            >
              <v-icon>mdi-check-circle</v-icon> PROCESAR
            </v-btn>
          </v-toolbar>
     
          <v-text-field
            v-model="idcontacto"
            prepend-icon="contacts"
            label="Buscar contacto"
            :rules="[]"
            class="ma-3"
            hide-details
          />
        

          <v-text-field
            v-model="numcli"
            hide-details
            prepend-icon="message"
            class="ma-3"
            label="Buscar cliente"
          />

          <v-text-field
            v-model="idproyecto"
            hide-details
            prepend-icon="person"
            class="ma-3"
            label="Proyecto"
          />
							
          <v-row class="ma-2">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaini"
                  hide-details
                  class="ma-2"
                  label="Fecha Inicio"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="fechaini"
                locale="es"
                @input="menu1 = false"
              />
            </v-menu>

            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechafin"
                  hide-details
                  class="ma-2"
                  label="Fecha Fin"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="fechafin"
                locale="es"
                @input="menu2 = false"
              />
            </v-menu>
          </v-row>

          <v-text-field
            v-model="factura"
            prepend-icon="notes"
            class="ma-3"
            label="Factura"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default{
  data(){
    return{
      snackbar: false,
      time: this.hora(),
      minutos:30,
      asunto:'',
      numcont:'',
      empresa:'',
      lugar:'',
      fechaini: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      factura:'',
      idproyecto:'',
      numcli:'',
      idcontacto: 0,
      nModo: 1
    }
  },
	
  computed:{
	    validaInfo(){
	      return  this.idcontacto   !== ''  &&
			  this.numcli !== '' &&
        this.idproyecto !== '' &&
			  this.fechaini !== '' &&
	    	this.fechafin !== '' &&
	    	this.factura !== ''
	    },
	  },

  created(){
    console.log('router', this.$route.params.poliza )

    let ruta = this.$route.params.poliza

	    //MODO EDITAR
	    if ( ruta !== undefined ){
	        this.nModo=2
	        this.readonly= true

	        this.CargarInfo(this.$route.params.poliza)
	        this.modo = 'Editar'

	     } else {
	     		console.log("nuevo")
	        //VALORES INICIALES ALTA
	        this.nModo = 1
	        this.modo  = 'Nuevo'
	    }

  },

  methods:{
    ...mapActions("snackbar", ["showSnack"]),


    CargarInfo(poliza){
      console.log("poliza", poliza)

      this.idcontacto = poliza.idcontacto
      this.numcli     = 	poliza.numcli
      this.idproyecto =	poliza.idproyecto
      this.fechaini   =	poliza.fechaini
      this.fechafin   =	poliza.fechafin
      this.factura    =	poliza.factura

    },

    agregar(){
				
      //VALIDAR INFORMACION
		    if(!this.validaInfo){
		    	 this.showSnack({
          text: "Debes registrar todos los campos",
          color: "orange",
          timeout: 3000,
        });

		    	console.log("FALLA EN LLENAR")
		        // this.snackbar = true ; this.color="orange"
		        // this.text = "Faltan llenar Datos. Nombre, email, telefono"
		      return
		    }


      var payload = {
        idcontacto: this.idcontacto,
        numcli: 	  this.numcli,
        idproyecto: 	this.idproyecto,
        fechaini: 	this.fechaini,
        fechafin: 	this.fechafin,
        factura: 	this.factura,
      }

      console.log("payload", payload)

	
	     //MODO NUEVO
      if (this.nModo == 1) {
        //Dedinir db name
        this.$http.post('v2/insert.polsop', payload).then(response =>{
			        this.showSnack({
		            text: "poliza Agregada correctamente",
		            color: "success",
		            timeout: 2000,
		          });

			        this.limpiarpoliza()
	            // setTimeout (() => this.$router.push({name:'catclientes'}),2000);
        }).catch(error=>{
            	// this.snackbar = true ;
	            // this.color="error"
	            // this.text = "Problemas para grabar el cliente"
	            console.log(error)
         			this.showSnack({
		            text: "Problema para agendar poliza."+ error,
		            color: "error",
		            timeout: 2000,
		          });
     
        })
        
      } else {
          
        // EDICION DE UNA poliza.
        this.$http.put('v2/update.polsop' + this.idpolsop, payload).then(response =>{
          this.showSnack({
		            text: "poliza Actualizada correctamente."+ error,
		            color: "error",
		            timeout: 2000,
		          });
          setTimeout (() => this.$router.push({name:'polizasopo'}),2000);

        }).catch(error=>{
            	this.showSnack({
		            text: "Problema para agendar poliza."+ error.error,
		            color: "error",
		            timeout: 2000,
		          });
          console.log(error)
        })

      }


    },
    limpiarpoliza(){
      this.idcontacto = ''
      this.numcli = ''
      this.fechaini = ''
      this.fechafin = ''
      this.idproyecto = ''
      this.factura = ''
    },
   				
   	  regresar(){
      console.log("ruta", this.$route.name)

      // MODO PANEL
      if (this.$route.name == '') {
        if (this.idpoliza == undefined){
          console.log("cerrar vista modal y limipiar. ")
          // this.$router.push({name:'panel'}).catch (err => {console.log(err)})
           
        } else {
          console.log("Catalogo regresar")
          this.$router.push({name:'polizasopo'})
        }
      }

      // MODO CATALOGO
      if (this.$route.name == 'poliza' ){
        this.$router.push({name:'polizasopo'})
      }

      
    },

	  hora(){
      var f =new Date();
      var hora = f.getHours()
      var minuto = f.getMinutes()
      var segundo = f.getSeconds()
      if(hora < 10){
        hora = "0" + f.getHours()
      }
      if(minuto < 10){
        minuto = "0" + f.getMinutes()
      }
      if(segundo < 10){
        segundo = "0" + f.getSeconds()
      }
        
      return hora + ":" + minuto + ":" + segundo
    },
  }
}
</script>